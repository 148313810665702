<template>
  <div class="page" :class="modal ? 'modalShow': ''">
    <section class="home-banner-area relative">
      <div class="container">
        <div
          class="row fullscreen d-flex align-items-center justify-content-center"
        >
          <div class="banner-content col-12">
            <!-- <h1 class="fadeIn">第二十届中国（漯河）食品博览会</h1>
            <h4 class="fadeIn">The 19th China (Luohe) Food Expo</h4>
            <p class="text-white">2022年5月16日 — 18日</p>
            <p class="text-white" style="margin-top: 10px">漯河国际会展中心</p>
 -->
            <img class="image1" :src="header_static" alt="" />
            <!-- <img class="image2" :src="header_static1" alt="" />
            <img class="image3" :src="header_static3" alt="" /> -->
            <div class="input-wrap">
              <!-- <form action="" class="form-box d-flex justify-content-between">
							<input type="text" placeholder="Search Courses" class="form-control" name="username">
							<button type="submit" class="btn search-btn">Search</button>
						</form> -->
              <!-- <button class="home_but" @click="openWeb('/advSignUp')">
                广告招商报名
              </button> -->
               <button class="home_but" @click="openWeb('/signUp')">
                采购商报名
              </button>
              <!-- <button class="home_but" @click="modal = true">
                采购商报名
              </button> -->
              <button class="home_but" @click="openWeb('/exSignUp')">
                参展报名
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 数字自增 -->
    <section class="w3l-stats py-lg-0" id="stats">
      <div class="gallery-inner container py-lg-0 py-3">
        <div class="row stats-con">
          <div class="col-lg-3 col-6 stats_info counter_grid">
            <div class="icon_item">
              <i class="iconfont iconshouye"></i>
            </div>
            <div class="text_item">
              <div class="number-grow-warp">
                <span
                  ref="numberGrow"
                  :data-time="2"
                  class="number-grow"
                  :data-value="value"
                  >0</span
                >
                <p>㎡</p>
              </div>
              <h5>展览面积</h5>
            </div>
          </div>
          <div class="col-lg-3 col-6 stats_info counter_grid1">
            <div class="icon_item">
              <i class="iconfont iconshangjia1"></i>
            </div>
            <div class="text_item">
              <div class="number-grow-warp">
                <span
                  ref="numberGrow1"
                  :data-time="2"
                  class="number-grow"
                  :data-value="value"
                  >0</span
                >
                <p>+</p>
              </div>
              <h5>标准展位</h5>
            </div>
          </div>
          <div class="col-lg-3 col-6 stats_info counter_grid mt-lg-0">
            <div class="icon_item">
              <i class="iconfont iconjigou"></i>
            </div>
            <div class="text_item">
              <div class="number-grow-warp">
                <span
                  ref="numberGrow2"
                  :data-time="2"
                  class="number-grow"
                  :data-value="value"
                  >0</span
                >
                <p>+</p>
              </div>
              <h5>参展企业</h5>
            </div>
          </div>
          <div class="col-lg-3 col-6 stats_info counter_grid2 mt-lg-0">
            <div class="icon_item">
              <i class="iconfont iconnavicon-rqfz"></i>
            </div>
            <div class="text_item">
              <div class="number-grow-warp">
                <span
                  ref="numberGrow3"
                  :data-time="2"
                  class="number-grow"
                  :data-value="value"
                  >0</span
                >
                <p>+</p>
              </div>
              <h5>专业观众</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Start 展品范围 -->
    <section class="feature-area home_exhibits_type">
      <div class="container">
        <div class="text-center">
          <img src="../assets/img/home/home_title1.png" alt="图片加载失败" />
        </div>
        <div class="row">
          <div
            class="type1_item col-lg-3"
            @click="openWeb('/displayArea?type=综合展区')"
          >
            <div class="feature-item feature-other">
              <img src="../assets/img/home/type1.png" alt="图片加载失败" />
              <p class="type_title">
                <span style="text-align: left; padding: 0 10px"
                  >综合展区<br />
                  <i>特色食品蔬果、特色农产品、有机食品、乳制品等。</i>
                </span>
              </p>
              <div class="type_details">
                <div>
                  <span>综合展区</span>
                  <br />
                  <em>查看详情</em>
                  <br />
                  <i>特色食品蔬果、特色农产品、有机食品、乳制品等。</i>
                </div>
              </div>
            </div>
          </div>
          <div class="type2_item col-lg-9">
            <div class="feature-inner row">
              <div
                class="col-lg-3 col-6"
                @click="openWeb('/displayArea?type=' + item.name)"
                v-for="(item, index) in typeData"
                :key="index"
              >
                <div class="feature-item">
                  <img :src="item.url" alt="图片加载失败" />
                  <p class="type_title">
                    <span>{{ item.name }}</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>{{ item.name }}</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type2.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>河南省特色食品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>河南省特色食品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type3.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>境外食品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>境外食品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type4.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>肉制品及冻品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>肉制品及冻品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type5.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>粮油及调味品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>粮油及调味品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type6.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>休闲食品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>休闲食品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type7.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>酒水饮料</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>酒水饮料</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type8.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>烘焙食品</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>烘焙食品</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="feature-item">
                  <img src="../assets/img/home/type9.png" alt="图片加载失败" />
                  <p class="type_title">
                    <span>食品加工机械</span>
                  </p>
                  <div class="type_details">
                    <div>
                      <span>食品加工机械</span>
                      <br />
                      <em>查看详情</em>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End 展品范围 -->
    <!-- 展会介绍 -->
    <section class="exhibition_desc">
      <div class="container">
        <div class="text-center">
          <img src="../assets/img/home/home_title2.png" alt="" />
        </div>
        <div class="video">
          <VueVideo :videoSrc="videoSrc" ref="video_element"></VueVideo>
        </div>
        <div class="exhibition_item">
          <p>
            中国（漯河）食品博览会是由中国食品工业协会、中国商业联合会、中国食品和包装机械工业协会、中国食品土畜进出口商会、商务部外贸发展局共同主办，漯河市人民政府承办、河南省博览事务局有限公司执行承办的食品类综合性会展，得到了河南省工信厅、河南省商务厅、河南省市场监管局等省直单位的大力支持，在国内具有较高知名度和较强影响力。
          </p>
          <p>
            会展举办地漯河市食品产业发达，是全国首家中国食品名城，孕育了全球最大的肉类加工企业万洲双汇集团，全国著名的农产品加工企业南街村集团，中国辣味休闲食品第一品牌卫龙食品等一批龙头企业；吸引了可口可乐、美国嘉吉、美国杜邦、中粮集团、台湾统一、旺旺集团等众多名企投资发展，食品工业已成为漯河市第一支柱产业。
          </p>
          <p>
            2003年起，漯河市以食为媒，连续19年举办食品博览会，取得了令人瞩目的成绩。“食博会”先后5次被商务部列为重点引导支持会展，被中国会展财富论坛评为“十大产业展览会”和“最具品牌价值展览会”。2018年，又被中国会展产业大会评为“改革开放40年，40个品牌展览会”之一；中国食品工业协会每年在大会期间发布食品产业信息数据，成为国内食品行业的“风向标”。
          </p>
        </div>
        <!-- <ul class="exhibition_image row">
          <li class="col-lg-4 col-md-6">
            <img src="../assets/img/home/desc1.png" alt="图片加载失败" />
          </li>
          <li class="col-lg-4 col-md-6">
            <img src="../assets/img/home/desc2.png" alt="图片加载失败" />
          </li>
          <li class="col-lg-4 col-md-6">
            <img src="../assets/img/home/desc3.png" alt="图片加载失败" />
          </li>
        </ul> -->
      </div>
      <!-- 展会介绍 -->
    </section>
    <!-- 精品参展商 -->
    <section class="boutique_exhibitors">
      <div class="container">
        <div class="text-center">
          <img src="../assets/img/home/home_title3.png" alt="" />
        </div>
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, i) in exhibitor"
              :key="item.id"
              :data-id="item.id"
            >
              <div class="slide_item_con">
                <div class="logo">
                  <img :src="item.logo" alt="logo加载失败" />
                </div>
                <p class="desc">
                  {{ item.introduction }}
                </p>
                <div class="image row">
                  <div
                    v-for="(ite, ie) in item.exhibitorsProductList"
                    :key="ie"
                    v-if="ie < 3"
                    class="col-lg-4 col-12"
                  >
                    <span
                      :style="{ backgroundImage: `url(${ite.path[0]})` }"
                    ></span>
                    <!-- <img :src="ite.path" alt="产品加载失败" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->
      </div>
    </section>
    <!-- 活动安排 -->
    <section class="activity_arrangements">
      <div class="container">
        <div class="text-center">
          <img src="../assets/img/home/home_title4.png" alt="" />
        </div>
        <div class="con_m">
          <ul class="list">
            <li v-for="(item, i) in planData" :key="i">
              <img :src="item.image" alt="图片加载失败" />
              <p class="desc" v-html="item.desc"></p>
            </li>
          </ul>
        </div>
        <div class="con_pc">
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, i) in planData"
                :key="i"
                @click="openWeb('/plan')"
              >
                <div class="slide_item_con">
                  <img :src="item.image" alt="图片加载失败" />
                  <p class="desc" v-html="item.desc"></p>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->
          </div>
        </div>
      </div>
    </section>
    <div class="sign_modal" v-show="modal">
      <div class="sign_modal_con">
        <div class="filter"></div>
        <div class="colse">
          <img :src="closeImg_static" @click="modal = false" alt="" title="关闭">
        </div>
        <div class="modal_content">
          <img :src="signImg_static" alt="">
          
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { getProByRe } from "@/api/index.js";
import VueVideo from "@/components/vueVideo";
// @ is an alias to /src
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  /*  props: {
    time: {
      type: Number,
      default: 2,
    },
    value: {
      type: Number,
      default: 720000,
    },
  }, */
  name: "Home",
  components: {
    VueVideo,
  },
  data() {
    return {
      modal: false,
      // header-static
      header_static: require("@/assets/img/header-static.png"),
      header_static1: require("@/assets/img/header-static2.png"),
      header_static3: require("@/assets/img/header-static3.png"),
      signImg_static: require("@/assets/img/sign_up_img.jpg"),
      closeImg_static: require("@/assets/img/closeIcon.png"),
      typeData: [
        {
          name: "未来食品",
          url: require("@/assets/img/home/type2.png"),
        },
        {
          name: "品牌企业",
          url: require("@/assets/img/home/type8.png"),
        },
        {
          name: "境外食品",
          url: require("@/assets/img/home/type3.png"),
        },
        {
          name: "预制菜",
          url: require("@/assets/img/home/type4.png"),
        },
        {
          name: "休闲食品",
          url: require("@/assets/img/home/type6.png"),
        },
        {
          name: "食药同源",
          url: require("@/assets/img/home/type9.png"),
        },
        {
          name: "酒水饮料",
          url: require("@/assets/img/home/type7.png"),
        },
        {
          name: "粮油及调味品",
          url: require("@/assets/img/home/type5.png"),
        },
      ],
      planData: [
        {
          image: require("@/assets/img/home/plan1.png"),
          desc: "2024中国（漯河）食品文化旅游节",
        },
        {
          image: require("@/assets/img/home/plan2.png"),
          desc: "沙澧河万灯迎宾千机飞“只有漯河”无人机表演",
        },
        {
          image: require("@/assets/img/home/plan4.png"),
          desc: "客商考察对接",
        },
        {
          image: require("@/assets/img/home/plan4.jpg"),
          desc: "云上食博会",
        },
      ],
      exhibitor: [
        /* {
          name: "双汇集团",
          image: require("@/assets/img/home/zt1.png"),
          desc:
            "双汇集团是中国最大的肉类加工基地，农业产业化国家重点龙头企业。在全国18个省（市）建有30多个现代化的肉类加工基地和配套产业，形成了养殖、饲料、屠宰、肉制品加工、新材料包装、冷链物流、连锁商业等完善的产业链，年产销肉类产品近400万吨，拥有100多万个销售终端，每天有1万多吨产品销往全国各地。",
          pro: [
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
          ],
        },
        {
          name: "卫龙",
          image: require("@/assets/img/home/zt2.png"),
          desc:
            "卫龙创办于1999年，是集研发、生产、加工和销售为一体的现代化休闲食品品牌。2006年10月“卫龙”食品获得“河南省优质产品”。2020年12月，卫龙品牌所属企业漯河市平平食品有限责任公司入选河南省人民政府公布的农业产业化省重点龙头企业名单。",
          pro: [
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
          ],
        },
        {
          name: "南街村",
          image: require("@/assets/img/home/zt3.png"),
          desc:
            "南街村集团是南街村创建的集体经济实体，下属28个企业，产业涉及食品、饮料、酒类、印刷、包装、医药、工艺品雕刻、旅游等。集团设有博士后科研工作站、省级企业技术中心和河南省小麦面制品工程技术研究中心。下属企业分别设立有面粉研究所、面制品研究所、调味料研究所、啤酒研究所、饮料研究所、制药研究所、化工研究所等。",
          pro: [
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
            {
              food1: require("@/assets/img/home/food.png"),
            },
          ],
        },
       */
      ],
      time: 2,
      value: 65000,
      videoSrc:
        "https://food-fair.oss-cn-beijing.aliyuncs.com/video/index_video.mp4",
    };
  },
  methods: {
    func_str(v) {
      console.log(v);
    },
    openWeb(v) {
      this.$router.push(v);
    },
    getSwiper2() {
      var mySwiper2 = new Swiper(".swiper2", {
        autoplay: true, //自动滑动
        speed: 800,
        // effect: "coverflow",
        loop: true, //是否无限循环
        // loopedSlides:2,
        autoplayDisableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        slidesPerView: 4, //一行显示3个
        // centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
        // slidesPerColumn: 2, //显示2行
        slideToClickedSlide: true, //点击切换
        // watchSlidesProgress: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click() {
            // this.$router.push("/server/organList");
          },
        },
      });
    },
    getSwiper1() {
      let vm = this;
      var mySwiper1 = new Swiper(".swiper1", {
        autoplay: true, //自动滑动
        speed: 1000,
        effect: "coverflow",
        loop: true, //是否无限循环
        loopedSlides: 2,
        slidesPerView: 2, //一行显示2个
        autoplayDisableOnInteraction: true, //用户操作swiper之后，是否禁止autoplay
        centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
        // slidesPerColumn: 2, //显示2行
        slideToClickedSlide: false, //点击切换
        preventClicksPropagation: true, //拖动swiper时阻止点击事件
        watchSlidesProgress: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        spaceBetween: "-50%",
        coverflowEffect: {
          rotate: 0, //旋转角度
          stretch: 1, //拉伸  图片间左右的间距和密集度
          depth: 150, //深度  切换图片间上下的间距和密集度
          modifier: 5, // 修正值   该值越大，前面的效果越明显
          slideShadows: false, //页面阴影效果
        },
        on: {
          click(v, e) {
            /* 
              自定义传参
              :data-id='item.id'
              得到传递的参数：
                this.clickedSlide.dataset.id
            */
            console.log(this.clickedSlide.dataset.id);
            vm.openWeb(
              "/exhibitorDetails?id=" + this.clickedSlide.dataset.id
              // vm.exhibitData[this.clickedSlide.dataset.swiperSlideIndex].id
            );
          },
        },
      });
    },
    init() {
      this.numberGrow(this.$refs.numberGrow, 65000);
      this.numberGrow(this.$refs.numberGrow1, 2500);
      this.numberGrow(this.$refs.numberGrow2, 1000);
      this.numberGrow(this.$refs.numberGrow3, 20000);
      this.getExhibitorData();
      this.getSwiper2();
    },
    getExhibitorData() {
      getProByRe().then((res) => {
        if (res.success) {
          // exhibitorsProductList
          res.result.forEach((it) => {
            it.exhibitorsProductList.forEach((item) => {
              item.path = JSON.parse(item.path);
            });
          });
          this.exhibitor = res.result;
          this.$nextTick(() => {
            this.getSwiper1();
          });
        }
      });
    },
    //   数字自增
    numberGrow(ele, value) {
      let _this = this;
      let step = (value * 10) / (_this.time * 1000);
      let current = 0;
      let start = 0;
      let t = setInterval(function () {
        start += step;
        if (start > value) {
          clearInterval(t);
          start = value;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = start;
        ele.innerHTML = current.toString();
        //   .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, "$1,");
      }, 10);
    },
  },
  created() {},
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.exhibition_desc ul.exhibition_image li {
  margin: 10px 0;
  /* width: calc(calc(100% - 100px) / 3);
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px; */
}
.exhibition_desc {
  text-align: center;
}
.exhibition_desc .home_title {
  color: red;
}
.exhibition_desc .exhibition_item {
  text-align: left;
  margin: 20px 0;
  p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    text-indent: 2rem;
  }
}
.exhibition_desc .exhibition_image li img {
  width: 100%;
}
.stats_info .text_item {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
  margin-left: 20px;
  text-align: left;
}
.icon_item {
  border: 1px solid #efd3d2;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  vertical-align: middle;
  margin: 20px 0;
  i {
    font-size: 30px;
    color: #aa120f;
    opacity: 0.2;
  }
}
.number-grow-warp {
  text-align: left;
  font-size: 24px;
  p {
    margin: 0;
  }
}
.home-banner-area {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .fadeIn {
    opacity: 0;
    transition: opacity 4s;
    opacity: 1;
    color: #fff;
  }
  .banner-content {
    .image1 {
      width: 80%;
      margin: 0 auto;
    }
    .image2 {
      margin-bottom: 20px;
      width: 40%;
    }
    .input-wrap {
      display: flex;
      justify-content: space-around;
      width: 60%;
      margin: 24px auto 0;
    }
  }
}
.page {
  position: relative;
  &.modalShow{
    width: 100%;
    height: calc(~"100vh - 540px");
    overflow:hidden;
  }
  .sign_modal{
    position: absolute;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    .filter{
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
    }
    .colse{
      position: absolute;
      right: 36%;
      top: 94px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 2222;
    }
    .sign_modal_con{
      position: relative;
      width: 100%;
      height: 100%;
      .modal_content{
        position: absolute;
        left:35%;
        top:90px;
        width:30%;
      }
      
    }
    img{
      width: 100%;
    }
  }
  // margin-top: 120px;
  .number-grow {
    color: #aa120f;
  }
  .stats-con {
    text-align: left;
  }
  .feature-item {
    img {
      width: 100%;
    }
  }
  .swiper-container {
    width: 100%;
    // height: 420px;
  }
  .swiper-slide {
    text-align: center;
    transform: scale(0.9);
    transition: transform 1s;
    padding: 0 20px;
    .slide_item_con {
      background: #ffffff;
      padding: 20px;
      border-radius: 8px;
      p {
        margin-top: 20px;
        text-align: left;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      div.image {
        div {
          width: calc(100% / 3);
          height: 200px;
          display: inline-block;
          vertical-align: middle;
          span {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            display: inline-block;
            height: 100%;
            border: 1px solid #eee;
          }
          img {
            border: 1px solid #eee;
            width: 100%;
            margin: 10px 10px;
          }
        }
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    border: 1px solid #dbdbdb;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #bbb;
    --swiper-navigation-size: 12px; /* 设置按钮大小 */
    background-color: #f9f9f9;
    position: absolute;
    bottom: 50%;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next {
    right: 0px;
  }
  .swiper2 {
    img {
      width: 100%;
    }
    .slide_item_con {
      padding: 0;
      p {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .swiper1 {
    padding: 10px 0px;
    .slide_item_con {
      box-shadow: 0px 0px 13px 5px rgba(73, 73, 73, 0.05);
      border-radius: 0px;
      cursor: pointer;
      div.logo {
        img {
          height: 80px;
        }
      }
    }
    .swiper-slide-active {
      // transform: scale3d(1) 1s;
      transform: scale(1);
      transition: transform 1s;
      .slide_item_con {
        box-shadow: 0px 0px 13px 5px rgba(38, 38, 38, 0.05);
        border-radius: 8px;
        .image {
          div {
            width: calc(100% / 3);
            padding: 10px;
            display: inline-block;
            vertical-align: middle;
            img {
              width: 100%;
            }
          }
        }
      }
      .but {
        display: block;
      }
    }
  }
}
@media (min-width: 781px) {
  .feature-other {
    height: 383px;
    overflow: hidden;
    .type_title {
      bottom: 93px;
    }
  }
  .activity_arrangements {
    .con_pc {
      display: block;
    }
    .con_m {
      display: none;
    }
  }
}
@media (max-width: 800px) {
  div.type_details {
    padding-top: 130px;
    display: none;
  }
  .home-banner-area {
    .banner-content {
      .image1 {
        width: 100%;
      }
      .image2 {
        width: 50%;
      }
      .input-wrap {
        width: 100%;
      }
    }
  }
}
@media (max-width: 780px) {
  .text-white {
    color: #fff;
    margin-top: 20px !important;
    font-size: 16px;
  }
  .feature-other {
    // height: 100%;
    position: relative;
    padding: 0px;
    .type_title {
      position: absolute;
      top: 200px;
      width: 100%;
    }
  }
  .input-wrap .home_but {
    font-size: 16px;
  }
  .type1_item {
    margin-bottom: 5px;
    padding: 0 5px;
    height: 200px;
    overflow: hidden;
  }
  .type2_item {
    .feature-item {
      padding: 0px;
    }
  }
  .slide_item_con {
    img {
      width: 100%;
    }
    p {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .activity_arrangements {
    .con_pc {
      display: none;
    }
    .con_m {
      display: block;
      ul.list {
        li {
          p {
            font-size: 14px;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .exhibition_desc .exhibition_item {
    p {
      font-size: 14px;
    }
  }
}
@media (max-width: 395px) {
  .input-wrap .home_but {
    font-size: 14px;
  }
  .number-grow-warp {
    font-size: 18px;
  }
}
</style>
