<template>
  <footer class="footer-area section-gap">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 single-footer-widget">
          <img src="@/assets/img/home/logo.png" alt="" />
          <!--  </div>
        <div class="col-lg-3 col-md-6 single-footer-widget"> -->
          <ul class="section1">
            <li>
              <span>
                <!-- <img src="@/assets/img/home/ico01.png" alt=""> -->
                <i class="iconfont iconhuodong"></i>
              </span>
              <span> 第二十一届中国(漯河)食品博览会 </span>
            </li>
            <li>
              <span>
                <!-- <img src="@/assets/img/home/ico02.png" alt=""> -->
                <i class="iconfont iconrili"></i>
              </span>
              <span> 2024年5月16日—18日 </span>
            </li>
            <li>
              <span>
                <!-- <img src="@/assets/img/home/ico03.png" alt=""> -->
                <i class="iconfont iconditu1"></i>
              </span>
              <span> 中国 · 河南 · 漯河国际会展中心 </span>
            </li>
            <li>
              <span>
                <!-- <img src="@/assets/img/home/ico04.png" alt=""> -->
                <i class="iconfont iconditu"></i>
              </span>
              <span> 漯河市郾城区嵩山支路 </span>
            </li>
          </ul>
        </div>
        <div class="col-lg-5 col-md-6 single-footer-widget">
          <ul>
            <li>
              <span>
                主办单位：中国食品工业协会、中国商业联合会、中国食品和包装机械工业协会、中国食品土畜进出口商会
              </span>
            </li>
            <li>
              <span>
                承办单位：河南省食品工业协会、河南省博览事务局有限公司
              </span>
            </li>
            <li>
              <span>
                支持单位：中原食品实验室、英国河南商会、马来西亚—中国商务理事会、马来西亚豫商联合会、泰中文化经济协会、白俄罗斯河南商会、香港食品安全交流协会
              </span>
            </li>
            <!-- <li>
              <span> 执行承办单位：河南省博览事务局有限公司 </span>
            </li> -->
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 single-footer-widget">
          <div class="code">
            <img src="@/assets/img/home/code1.png" alt="" />
            <p>食博会公众号</p>
          </div>
          <div class="code">
            <img src="@/assets/img/home/code2.jpg" alt="" />
            <p>食博会小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bottom_item"
      style="text-align: center; border-top: 1px solid rgb(117, 115, 115, 0.2)"
    >
      <p style="padding: 10px 0 0 0">
        © 2024 版权所有 漯河食品博览会组委会 | 组委会电话：0371-63577569 |
        <span
          style="cursor: pointer"
          @click="openNew('https://beian.miit.gov.cn/')"
          >豫ICP备17034881号-6</span
        >
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    openNew(v) {
      window.open(v);
    },
  },
};
</script>

<style scoped lang="less">
.footer-area {
  color: #7f8bab;
  text-align: left;
  ul.section1 {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    max-width: calc(100% - 100px);
    li {
      margin: 0;
    }
  }
  // margin-top: 20px;
  // .section-gap{
  padding: 20px 0 10px 0 !important;
  // }
  div.code {
    display: inline-block;
    text-align: center;
    padding: 6px;
    width: 50%;
    p {
      font-size: 15px;
      margin: 0;
      padding-top: 6px;
    }
    img {
      width: 100%;
    }
  }
  div.bottom_item {
    p {
      font-size: 15px;
      margin: 0;
    }
  }
}
@media (min-width: 750px) {
  ul.section1 {
    padding: 0 ;
    div.code {
      padding: 6px 30px;
    }
  }
}
@media (max-width: 750px) {
  .footer-area {
    div.code {
      padding: 6px 30px;
    }
  }
}
</style>